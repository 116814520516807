import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FetchLiveClashes } from "../helpers/FetchData";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

import {
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import moment from "moment";

const LiveClashCheck: React.FC<any> = ({ rule, config }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clashes, setClashes] = useState<any>({ running: [], started: [] });
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (
      rule.meta &&
      rule.meta.version &&
      rule.meta.version >= 2 &&
      !rule.region_key
    ) {
      FetchLiveClashes((output: any) => {
        if (output?.status !== 200) {
          setClashes({ running: [], started: [] });
        } else {
          setClashes(output.response);
        }
      }, rule.id);
    }
  }, [rule]);

  const handlDialogOpen = () => {
    if (!rule.clashes) {
      return;
    }
    setDialogOpen(true);
  };
  const renderValues = (i: any, type: string) => {
    return i.map((e: any, index: any) => {
      return (
        <Box key={e} display="inline-block">
          {index > 0 ? ", " : ""}
          <span className={rule.meta[type].includes(e) ? "has-match" : ""}>
            {e}
          </span>
        </Box>
      );
    });
  };

  const renderPages = (pages: any) => {
    return pages.map((page: any, index: any) => {
      return (
        <Box key={page.id} display="inline-block">
          {index > 0 ? ", " : ""}
          <span
            className={
              rule.meta.decision_pages.filter((i: any) => i.id === page.id)
                .length
                ? "has-match"
                : ""
            }
          >
            {page.name}
          </span>
        </Box>
      );
    });
  };
  const renderTableRow = (i: any) => {
    const start = i.state >= 30 ? i.start_time : i.planned_start_date;
    const stop = i.state > 30 ? i.end_time : i.planned_end_date;
    return (
      <TableRow
        key={i.id}
        onClick={(e) => {
          navigate(`/experiment/${i.id}/`);
        }}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>{i.name}</TableCell>
        <TableCell>{moment(start).format("MMM Do")}</TableCell>
        <TableCell>{moment(stop).format("MMM Do")}</TableCell>
        <TableCell>{renderValues(i.meta.locales, "locales")}</TableCell>
        <TableCell>{renderValues(i.meta.touchpoints, "touchpoints")}</TableCell>
        <TableCell>{renderPages(i.meta.decision_pages)}</TableCell>
        <TableCell>{i.owner}</TableCell>
        <TableCell>{i.developer}</TableCell>
      </TableRow>
    );
  };
  return (
    <>
      {(clashes.running.length > 0 || clashes.started.length > 0) && (
        <Typography
          variant="subtitle2"
          display="inline"
          fontSize="small"
          fontStyle={"italic"}
        >
          This test is has clashes.{" "}
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {!rule.clashes || rule.clashes === -1
                  ? "Clashcheck unavailable"
                  : rule.clashes > 0
                  ? "Clashes found"
                  : "No clashes found"}
              </div>
            }
          >
            <IconButton onClick={handlDialogOpen}>
              <HeartBrokenIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      )}

      <Dialog
        open={dialogOpen}
        fullWidth={true}
        maxWidth={rule.clashes > 0 ? "xl" : "xs"}
        sx={{ minWidth: "200px" }}
        onClose={() => {
          setDialogOpen(false);
        }}
        className={config.darkMode ? "darkmode" : ""}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {rule.clashes === -1 ? (
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12}>
                <Typography sx={{ mb: 2 }} variant="body2">
                  You must provide all necessary data to automatically check for
                  clashes:
                </Typography>
                <List dense={true} className="clashinfo-list">
                  <ListItem>
                    <ListItemText primary="Planned start date" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Planned end date" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Locale" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Touchpoint" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Owner" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Developer" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          ) : rule.clashes > 0 ? (
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead sx={{ bgcolor: theme.palette.divider }}>
                      <TableRow>
                        <TableCell>Experiment name</TableCell>
                        <TableCell>Start date</TableCell>
                        <TableCell>End date</TableCell>
                        <TableCell>Locales</TableCell>
                        <TableCell>Touchpoints</TableCell>
                        <TableCell>Pages</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Developer</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          display: clashes.running.length < 1 ? "none" : "",
                        }}
                      >
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontStyle: "italic",
                          }}
                        >
                          Experiments already running or planned when this
                          started.
                        </TableCell>
                      </TableRow>
                      {clashes.running.map((i: any) => renderTableRow(i))}
                      <TableRow
                        sx={{
                          display: clashes.started.length < 1 ? "none" : "",
                        }}
                      >
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontStyle: "italic",
                          }}
                        >
                          Experiments started after this experiment.
                        </TableCell>
                      </TableRow>
                      {clashes.started.map((i: any) => renderTableRow(i))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : (
            <>No clashes</>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LiveClashCheck;
