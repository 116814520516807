import { DetailsTable } from "./DetailsTable";
import { Grid } from "@mui/material";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import LiveClashCheck from "../Watchtower/LiveClashCheck";

export const HypothesisAndDetails = (props: any) => {
  const theme = useTheme();
  const [brandName, setBrandName] = useState("");

  useEffect(() => {
    setBrandName(getBrandName(props.ruleData.brand_id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getBrandName = (brandId: number) => {
    const brand = props.config?.brands?.find(
      (brand: any) => brand.id === brandId
    );
    const result = brand ? brand["name"] : "unknown";
    return result;
  };
  return (
    <Card variant="outlined" sx={props.customSx}>
      <CardHeader
        title={props.ruleData.name}
        titleTypographyProps={{
          variant: "button",
          fontWeight: "bold",
          noWrap: true,
        }}
        subheader={brandName}
        subheaderTypographyProps={{ variant: "caption" }}
        sx={{
          backgroundColor: theme.palette.divider,
          maxHeight: "56px",
          width: "100%",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
        action={
          <Tooltip
            title={props.config.cms_strings.results.experiment_config_tooltip}
          >
            <IconButton
              component={Link}
              to={"/features/" + props.flag_id + "/" + props.id}
              sx={{ mt: "-5px" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <Grid container spacing={3} sx={{ padding: "30px 10px 0 10px" }}>
          <Grid
            item
            xs={8}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
              padding: "10px",
            }}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography align="left" variant="h6" sx={{ fontSize: "16px" }}>
                Hypothesis
              </Typography>
              <Typography
                align="left"
                sx={{ fontSize: "14px" }}
                paragraph={true}
              >
                {props.ruleData.hypothesis}
              </Typography>
              <Typography align="left" variant="h6" sx={{ fontSize: "16px" }}>
                Description
              </Typography>
              <Typography
                align="left"
                sx={{ fontSize: "14px" }}
                paragraph={true}
              >
                {props.ruleData.description}
              </Typography>
              <Typography variant="subtitle2" display="inline" fontSize="small">
                Type:{" "}
              </Typography>
              <Typography variant="body2" display="inline" fontSize="small">
                {props.ruleData.type === "mab"
                  ? "Multi-armed bandit"
                  : "A/B-Test"}
              </Typography>
            </Grid>
            {props.ruleData.meta && props.ruleData.meta.version > 1 && (
              <Grid item>
                <LiveClashCheck rule={props.ruleData} config={props.config} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4}>
            <DetailsTable {...props} textColor="#000000" />
          </Grid>
          {/*<div className="expand-button">
          <ExpandMoreIcon />
        </div>*/}
        </Grid>
      </CardContent>
    </Card>
  );
};
