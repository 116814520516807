import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FetchClashes } from "../helpers/FetchData";
import { cms_strings } from "../CMS/cms_strings";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";

import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Box,
  Alert,
} from "@mui/material";
import moment from "moment";

const ClashCheck: React.FC<any> = ({ rule, config }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clashes, setClashes] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      rule.clashes &&
      rule.clashes > 0 &&
      rule.meta &&
      rule.meta.version &&
      rule.meta.version >= 2
    ) {
      FetchClashes((output: any) => {
        if (output?.status !== 200) {
          setClashes([]);
        } else {
          setClashes(output.response);
        }
      }, rule.id);
    }
  }, [rule]);

  const handlDialogOpen = () => {
    if (!rule.clashes) {
      return;
    }
    setDialogOpen(true);
  };
  const renderValues = (i: any, type: string) => {
    return i.map((e: any, index: any) => {
      return (
        <Box key={e} display="inline-block">
          {index > 0 ? ", " : ""}
          <span className={rule.meta[type].includes(e) ? "has-match" : ""}>
            {e}
          </span>
        </Box>
      );
    });
  };

  const renderPages = (pages: any) => {
    return pages.map((page: any, index: any) => {
      return (
        <Box key={page.id} display="inline-block">
          {index > 0 ? ", " : ""}
          <span
            className={
              rule.meta.decision_pages.filter((i: any) => i.id === page.id)
                .length
                ? "has-match"
                : ""
            }
          >
            {page.name}
          </span>
        </Box>
      );
    });
  };
  return (
    <>
      {rule.meta &&
        rule.meta.version &&
        rule.meta.version >= 2 &&
        !rule.region_key && (
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-line" }}>
                {!rule.clashes || rule.clashes === -1
                  ? "Clashcheck unavailable"
                  : rule.clashes > 0
                  ? "Clashes found"
                  : "No clashes found"}
              </div>
            }
          >
            <IconButton
              onClick={handlDialogOpen}
              className={rule.clashes > 0 ? "pulsate" : ""} // Add pulsate class if there are clashes
            >
              {!rule.clashes || rule.clashes === -1 ? (
                <FavoriteBorderIcon />
              ) : rule.clashes > 0 ? (
                <HeartBrokenIcon color="error" />
              ) : (
                <FavoriteIcon color="success" />
              )}
            </IconButton>
          </Tooltip>
        )}

      <Dialog
        open={dialogOpen}
        fullWidth={true}
        maxWidth={rule.clashes > 0 ? "xl" : "xs"}
        sx={{ minWidth: "200px" }}
        onClose={() => {
          setDialogOpen(false);
        }}
        className={config.darkMode ? "darkmode" : ""}
      >
        <DialogTitle>Clashes</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {rule.clashes === -1 ? (
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12}>
                <Typography sx={{ mb: 2 }} variant="body2">
                  You must provide all necessary data to automatically check for
                  clashes:
                </Typography>
                <List dense={true} className="clashinfo-list">
                  <ListItem>
                    <ListItemText primary="Planned start date" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Planned end date" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Locale" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Touchpoint" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Owner" />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Developer" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          ) : rule.clashes > 0 ? (
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12}>
                <Alert severity="info">
                  {cms_strings.experiments.clashes_warning}
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Experiment name</TableCell>
                        <TableCell>Start date</TableCell>
                        <TableCell>End date</TableCell>
                        <TableCell>Locales</TableCell>
                        <TableCell>Touchpoints</TableCell>
                        <TableCell>Pages</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Developer</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clashes.map((i: any) => (
                        <TableRow
                          key={i.id}
                          onClick={(e) => {
                            navigate(`/experiment/${i.id}/`);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell>{i.name}</TableCell>
                          <TableCell>
                            {moment(i.planned_start_date).format("MMM Do")}
                          </TableCell>
                          <TableCell>
                            {moment(i.planned_end_date).format("MMM Do")}
                          </TableCell>
                          <TableCell>
                            {renderValues(i.meta.locales, "locales")}
                          </TableCell>
                          <TableCell>
                            {renderValues(i.meta.touchpoints, "touchpoints")}
                          </TableCell>
                          <TableCell>
                            {renderPages(i.meta.decision_pages)}
                          </TableCell>
                          <TableCell>{i.owner}</TableCell>
                          <TableCell>{i.developer}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : (
            <>No clashes</>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClashCheck;
