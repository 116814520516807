import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import {
  Toolbar,
  Typography,
  CssBaseline,
  Box,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

/* Icons */
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ExtensionIcon from "@mui/icons-material/Extension";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Info from "@mui/icons-material/Info";
import ModeComment from "@mui/icons-material/ModeComment";
import SettingsIcon from "@mui/icons-material/Settings";
import Remove from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import InsightsIcon from "@mui/icons-material/Insights";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import Brightness6OutlinedIcon from "@mui/icons-material/Brightness6Outlined";
import CellTowerIcon from "@mui/icons-material/CellTower";
import HistoryIcon from "@mui/icons-material/History";

import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../helpers/authConfig";

import {
  styled,
  Theme,
  CSSObject,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

import { useIsAuthenticated } from "@azure/msal-react";

import Welcome from "../welcome/Welcome";
import logo from "../../assets/fabulous.png";
import logolight from "../../assets/fabulous-light.png";
import ee from "../../assets/ee-darker.png";
import cs from "../../assets/cs-darker.png";

import { SnackbarProvider, closeSnackbar } from "notistack";
import BackdropSpinner from "../common/BackdropSpinner";
import { useConfig } from "../../context";
import TokenManager from "../common/TokenManager";
import SiteMessage from "../common/SiteMessage";
import { interaction } from "../../Tracking";

const menuItems: {
  to: string;
  name: string;
  icon: JSX.Element;
  divider?: boolean;
  external?: boolean;
  children?: any;
}[] = [
  {
    to: "/",
    name: "Start",
    icon: <HomeIcon />,
  },
  {
    to: "/features",
    name: "Features",
    icon: <ExtensionIcon />,
  },
  {
    to: "/results",
    name: "Results",
    icon: <BarChartIcon />,
  },
  {
    to: "/programreporting",
    name: "Program Reporting",
    icon: <InsightsIcon />,
  },
  {
    to: "/pages/ee",
    name: "Effective Experiments",
    icon: <Box component="img" sx={{ height: 24 }} alt="Logo" src={ee} />,
    divider: true,
  },
  {
    to: "/pages/cs",
    name: "ContentSquare",
    icon: <Box component="img" sx={{ height: 24 }} alt="Logo" src={cs} />,
    children: [
      { to: "/pages/cs-segments", name: "Segments in CS", icon: <Remove /> },
    ],
  },
  {
    to: "/pages/support",
    name: "Support",
    icon: <Info />,
    divider: true,
  },
  {
    to: "/pages/feedback",
    name: "Feedback",
    icon: <ModeComment />,
  },
];

const checkSelected = (to: string, pathname: string, checkParent?: boolean) => {
  if (to === "/") {
    return pathname.length === 1;
  } else {
    if (checkParent) {
      return pathname.includes(to);
    } else {
      return pathname === to;
    }
  }
};

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export interface IAppProps {}
const PageLayout: React.FC<IAppProps> = (props) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [open, setOpen] = React.useState(
    localStorage.getItem("fabulousMenuClosed") ? false : true
  );
  const [userInfo, setUserInfo] = React.useState({ name: "", role: "" } as {
    name: string;
    role: string;
  });
  const { config } = useConfig();

  const [darkMode, setDarkMode] = React.useState(false);

  useEffect(() => {
    const storedDark: any = localStorage.getItem("darkMode");
    if (storedDark === "true") {
      setDarkMode(true);
      config.darkMode = true;
    }
  }, [config]);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          background: {
            paper: darkMode ? "#121212" : "white",
            default: darkMode ? "#303030" : "#f3f3f3",
          },
        },
      }),
    [darkMode]
  );
  const toggleDarkMode = () => {
    if (darkMode) {
      localStorage.removeItem("darkMode");
      setDarkMode(false);
      config.darkMode = false;
    } else {
      localStorage.setItem("darkMode", "true");
      setDarkMode(true);
      config.darkMode = true;
    }
    interaction("dark_mode");
  };

  useEffect(() => {
    if (config.user && config.user.email) {
      const email: string = config.user.email;
      const name: string = email.split("@")[0].replace(".", " ");
      const role: string =
        config.user.role[0].toUpperCase() +
        config.user.role.slice(1).toLowerCase().replace("_", " ");
      setUserInfo({ name: name, role: role });
    }
  }, [config]);

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      account: instance.getAllAccounts()[0],
    });
  };
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.removeItem("fabulousMenuClosed");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("fabulousMenuClosed", "true");
  };

  const linkContent = (item: any, open: boolean) => {
    return (
      <>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
      </>
    );
  };
  const internalLink = (item: any, pathname: string, open: boolean) => {
    return (
      <ListItemButton
        component={RouterLink}
        to={item.to}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          "&.Mui-selected": { backgroundColor: "rgb(184 225 143)" },
        }}
        selected={checkSelected(item.to, pathname)}
      >
        {linkContent(item, open)}
      </ListItemButton>
    );
  };

  const externalLink = (item: any, pathname: string, open: boolean) => {
    return (
      <ListItemButton
        component="a"
        href={item.to}
        target="_blank"
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          "&.Mui-selected": { backgroundColor: "rgb(184 225 143)" },
        }}
      >
        {linkContent(item, open)}
      </ListItemButton>
    );
  };

  const LoggedInDrawer = () => {
    const { pathname } = useLocation();
    return (
      <>
        <List component="nav">
          {menuItems.map((item) => (
            <Box key={item.to}>
              <ListItem disablePadding sx={{ display: "block" }}>
                {item.divider ? <Divider sx={{ mt: 1, mb: 1 }} /> : null}
                {item.external
                  ? externalLink(item, pathname, open)
                  : internalLink(item, pathname, open)}
              </ListItem>
              {item.children ? (
                <List
                  dense={true}
                  key={item.to + "child"}
                  sx={{
                    display: checkSelected(item.to, pathname, true)
                      ? "block"
                      : "none",
                    "& svg": { marginLeft: "20px" },
                  }}
                >
                  {item.children.map((child: any) => (
                    <ListItem
                      key={child.to}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      {internalLink(child, pathname, open)}
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Box>
          ))}
        </List>
        <Divider />
        {userInfo.role === "Administrator" ? <AdminDrawer /> : <></>}
        <List>
          <ListItem key="logout" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={handleLogout}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  };
  const LoggedOutDrawer = () => {
    return (
      <List>
        <ListItem key="logout" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={handleLogin}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary="Sign in" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
    );
  };

  const AdminDrawer = () => {
    const { pathname } = useLocation();
    return (
      <List component="nav">
        <ListItem key="cms-manager" disablePadding sx={{ display: "block" }}>
          {internalLink(
            { to: "/admin/cms", name: "Settings", icon: <SettingsIcon /> },
            pathname,
            open
          )}
        </ListItem>
        <ListItem key="history" disablePadding sx={{ display: "block" }}>
          {internalLink(
            { to: "/admin/history", name: "History", icon: <HistoryIcon /> },
            pathname,
            open
          )}
        </ListItem>
        <ListItem key="watchtower" disablePadding sx={{ display: "block" }}>
          {internalLink(
            { to: "/watchtower", name: "Watchtower", icon: <CellTowerIcon /> },
            pathname,
            open
          )}
        </ListItem>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </List>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderBottom:
              process.env.REACT_APP_ENV === "production"
                ? "3px solid rgb(184 225 143)"
                : "8px solid red",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
            <Typography
              component="div"
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
            >
              <Box
                component="img"
                sx={{ height: 54 }}
                alt="Logo"
                src={darkMode ? logolight : logo}
              />
            </Typography>
            <IconButton onClick={toggleDarkMode} sx={{ mr: 2 }}>
              {darkMode ? <Brightness6OutlinedIcon /> : <Brightness6Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{ justifyContent: "space-between" }}>
            <Box sx={{ ml: 2, maxWidth: "80%", textTransform: "capitalize" }}>
              <Typography variant="body1" noWrap>
                {userInfo.name}
              </Typography>
              <Typography variant="body2" noWrap>
                {userInfo.role}
              </Typography>
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {isAuthenticated ? <LoggedInDrawer /> : <LoggedOutDrawer />}
        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <>
            <DrawerHeader />
            {isAuthenticated ? <SiteMessage /> : <> </>}
            <SnackbarProvider
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              maxSnack={10}
              hideIconVariant={true}
              disableWindowBlurListener={true}
              autoHideDuration={10000}
              action={(snackbarId) => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                  <ClearIcon />
                </IconButton>
              )}
            />
            {isAuthenticated ? props.children : <Welcome />}
          </>
          <BackdropSpinner />
          {isAuthenticated ? <TokenManager /> : <></>}
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default PageLayout;
