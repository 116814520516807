import React from "react";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { isLiveValid } from "../helpers/Helpers";
import { UpdateRuleState } from "../helpers/FetchData";
import { useConfig } from "./../context";
import { enqueueSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";

const ChangeState: React.FC<any> = ({
  ruleData,
  currentState,
  disabled,
  callback,
  listView,
}) => {
  const { config, setConfig } = useConfig();
  const confirm = useConfirm();

  const toggleBackdrop = (b: boolean) => {
    setConfig((prevState: any) => {
      return {
        ...prevState,
        showBackdrop: b,
      };
    });
  };

  const getStateName = (state_id: Number) => {
    const state = config?.rule_states?.find((e: any) => {
      return e.state_id === state_id;
    });
    return state ? state["name"] : "";
  };
  const updateState = (state: any) => {
    if (state === 30 || state === 20 || state === 10) {
      if (!isLiveValid(ruleData, state)) {
        return;
      }
    }

    toggleBackdrop(true);
    UpdateRuleState(
      (output: any) => {
        if (output?.status !== 200) {
          enqueueSnackbar(output.message, { variant: "error" });
        } else {
          const message = `"${ruleData.name}" state set to ${getStateName(
            state
          )}`;
          enqueueSnackbar(message, { variant: "success" });

          callback(ruleData.id, state);
        }
        toggleBackdrop(false);
      },
      Number(ruleData.id),
      {
        from_state: ruleData.state,
        state: state,
      }
    );
  };
  const handleStateChange = (event: any) => {
    let confirmation = false;
    const confirm_options: any = {
      title: "Change state",
      description: "",
      confirmationButtonProps: { color: "primary", variant: "contained" },
    };
    if (event.target.value === 30) {
      if (ruleData.clashes > 0) {
        confirm_options.title = "Experiment has clashes!";
        confirm_options.description =
          config.cms_strings.experiments.state_change_30_clashes;
        confirm_options.acknowledgement = "I have acknowledged the clashes";
      } else {
        confirm_options.description =
          config.cms_strings.experiments.state_change_30;
      }
      confirmation = true;
    } else if (event.target.value === 40) {
      confirm_options.description =
        config.cms_strings.experiments.state_change_40;
      confirmation = true;
    }

    if (confirmation) {
      confirm(confirm_options)
        .then(() => {
          updateState(event.target.value);
        })
        .catch(() => {
          console.log("Cancelled");
        });
    } else {
      updateState(event.target.value);
    }
  };

  const checkDisabled = (state_id: number) => {
    return (currentState === 30 && state_id !== 40) ||
      (currentState >= 30 && state_id < currentState)
      ? true
      : false;
  };
  if (Object.keys(config).length === 0) return <div>Loading states</div>; // Waiting for config
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          disabled={disabled}
          value={currentState}
          onChange={handleStateChange}
          label="State"
          name={ruleData.id.toString()}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          {config.rule_states.map(
            (item: { name: string; state_id: number }) => {
              return item.state_id === 30 &&
                ruleData.clashes > 0 &&
                listView &&
                ruleData.state < 30 ? (
                <MenuItem key={item.name} value={item.state_id} disabled={true}>
                  {item.name}{" "}
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "0.5rem", color: "red", pl: 1 }}
                  >
                    Clashes
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  key={item.name}
                  value={item.state_id}
                  disabled={checkDisabled(item.state_id)}
                >
                  {item.name}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default ChangeState;
