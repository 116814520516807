import React, { useEffect, useState, useCallback } from "react";

import {
  Typography,
  Paper,
  Grid,
  Container,
  Alert,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  TextField,
  Link,
} from "@mui/material";
import { FetchHistory } from "../helpers/FetchData";
import { useConfig } from "../context";
import Loader from "../components/common/Loader";
import moment from "moment";
// @ts-ignore
import { JsonEditor } from "jsoneditor-react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDebounce } from "../helpers/useDebounce";

const History: React.FC<{}> = () => {
  const [data, setData] = React.useState<any>([]);
  const { config, setConfig } = useConfig();
  const [userLevel, setUserLevel] = useState(0);
  const [currentDataIndex, setCurrentDataIndex] = React.useState(-1);
  const [filter, setFilter] = React.useState<any>({
    type: "0",
    action: "0",
    target_id: "",
  });
  const [page, setPage] = React.useState(1);
  const debouncedFilter = useDebounce<any>(filter, 500);
  const PAGE_SIZE = 26;
  const toggleBackdrop = useCallback(
    (b: boolean) => {
      setConfig((prevState: any) => {
        return {
          ...prevState,
          showBackdrop: b,
        };
      });
    },
    [setConfig]
  );

  useEffect(() => {
    const filterData = [];
    for (const key in debouncedFilter) {
      if (debouncedFilter[key] !== "0" && debouncedFilter[key] !== "") {
        filterData.push({
          name: key,
          value: debouncedFilter[key],
          match: "eq",
        });
      }
    }
    const data = { page: page, page_size: PAGE_SIZE, filter: filterData };
    toggleBackdrop(true);
    FetchHistory((output: any) => {
      // Fixa 0 response från FetchHistory

      if (output.status === 200) {
        setData(output.response);
        toggleBackdrop(false);
      }
    }, data);
  }, [page, debouncedFilter, toggleBackdrop]);

  useEffect(() => {
    if (config.user && config.user.level) {
      setUserLevel(config.user.level);
    }
  }, [config]);

  const generateLinks = (item: any, index: number) => {
    const segment =
      item.type === "rules" || item.type === "schedules"
        ? "experiment"
        : "features";
    return (
      <Box>
        <IconButton onClick={(e: any) => handleOpen(e, index)} size="small">
          <DataObjectIcon />
        </IconButton>
        <IconButton
          sx={{ visibility: item.target_id > 0 ? "visible" : "hidden" }}
          href={`/${segment}/${item.target_id}`}
          target="_blank"
          size="small"
        >
          <OpenInNewIcon />
        </IconButton>
      </Box>
    );
  };

  const handleOpen = (e: any, index: number) => {
    setCurrentDataIndex(index);
  };

  const handleIdClick = (e: any, id: number) => {
    toggleBackdrop(true);
    setFilter((prevState: any) => {
      let newFilter: any = { ...prevState };
      newFilter.target_id = id;
      return newFilter;
    });
  };

  const clearFilters = () => {
    toggleBackdrop(true);
    setFilter({ type: "0", action: "0", target_id: "" });
  };

  const handleFilterChange = (e: any) => {
    setFilter((prevState: any) => {
      let newFilter: any = { ...prevState };
      newFilter[e.target.name] = e.target.value;
      return newFilter;
    });
  };

  const historyRow = (item: any, index: number) => {
    return (
      <TableRow key={index} sx={{ minHeight: "50px" }}>
        <TableCell align="left">
          {moment(item.date_time).format("YYYY-MM-DD HH:mm")}
        </TableCell>
        <TableCell align="left">{item.user}</TableCell>
        <TableCell align="right">{item.type}</TableCell>
        <TableCell align="right">{item.action}</TableCell>
        <TableCell align="right">
          {item.target_id > 0 ? (
            <Link
              sx={{ cursor: "pointer" }}
              onClick={(e: any) => handleIdClick(e, item.target_id)}
            >
              {item.target_id}
            </Link>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="right">{generateLinks(item, index)}</TableCell>
      </TableRow>
    );
  };
  return userLevel === 0 ? (
    <Loader />
  ) : userLevel < 100 ? (
    <Alert severity="error">You don't have permission to view this page.</Alert>
  ) : (
    <Container>
      <Paper sx={{ p: 2 }}>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item xs={12}>
            <Typography mb={1} variant="h5">
              History
            </Typography>
            <Typography variant="body2"></Typography>
          </Grid>
          <Grid container justifyContent="flex-start">
            <Grid item md={2} xs={6}></Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            sx={{ pt: "0!important", mt: "0!important" }}
          >
            <TableContainer className="history-table">
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Date/time</TableCell>
                    <TableCell align="left">User</TableCell>
                    <TableCell align="right">
                      <FormControl sx={{ m: 1, width: "120px" }} size="small">
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                          value={filter.type}
                          name="type"
                          onChange={handleFilterChange}
                          labelId="type-select-label"
                          id="type-select"
                          label="Type"
                        >
                          <MenuItem value="0">All</MenuItem>
                          <MenuItem key="admin" value="admin">
                            Admin
                          </MenuItem>
                          <MenuItem key="rules" value="rules">
                            Rules
                          </MenuItem>
                          <MenuItem key="flags" value="flags">
                            Flags
                          </MenuItem>
                          <MenuItem key="schedules" value="schedules">
                            Schedules
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <FormControl sx={{ m: 1, width: "120px" }} size="small">
                        <InputLabel id="type-select-label">Action</InputLabel>
                        <Select
                          value={filter.action}
                          name="action"
                          onChange={handleFilterChange}
                          labelId="type-select-label"
                          id="type-select"
                          label="Action"
                        >
                          <MenuItem value="0">All</MenuItem>
                          <MenuItem key="create" value="create">
                            Create
                          </MenuItem>
                          <MenuItem key="update" value="update">
                            Update
                          </MenuItem>
                          <MenuItem key="state" value="state">
                            State
                          </MenuItem>
                          <MenuItem key="archive" value="archive">
                            Archive
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        margin="none"
                        value={filter.id === "0" ? "" : filter.target_id}
                        type="tel"
                        label="Id"
                        size="small"
                        name="target_id"
                        autoComplete="off"
                        sx={{ width: "70px" }}
                        onChange={handleFilterChange}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={clearFilters}
                        sx={{ height: "100%", ml: 2, opacity: 0.5 }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 ? (
                    data.map((item: any, index: number) =>
                      historyRow(item, index)
                    )
                  ) : (
                    <TableRow key="nodata">
                      <TableCell colSpan={6}>
                        <Paper sx={{ mt: 2, width: "100%" }}>
                          <Alert severity="info">No data.</Alert>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid
              container
              spacing={2}
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  disabled={page < 2}
                  onClick={() => setPage((prevState) => prevState - 1)}
                >
                  &lt;
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <b>Page:</b> {page}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  disabled={data.length < PAGE_SIZE}
                  onClick={() => setPage((prevState) => prevState + 1)}
                >
                  &gt;
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={currentDataIndex > -1}
        onClose={() => setCurrentDataIndex(-1)}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>Data</DialogTitle>
        <DialogContent className="history-dialog">
          {currentDataIndex > -1 ? (
            <JsonEditor value={data[currentDataIndex].data} mode="view" />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={() => setCurrentDataIndex(-1)}
                variant="outlined"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default History;
