import React from "react";

import {
  TextField,
  Slider,
  Stack,
  Paper,
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Alert,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment, { Moment } from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ChangeDecisionPage from "./ChangeDecisionPage";
import ChangeDecisionPages from "./ChangeDecisionPages";
import Loader from "../components/common/Loader";
import HelpToolTip from "../components/common/HelpToolTip";
import { useConfig } from "../context";
import { useTitle } from "../helpers/useTitle";

const GeneralFields: React.FC<any> = ({
  newRule,
  onChangeHandler,
  sliderAllocation,
  handleSliderChange,
  handleSliderCommitted,
  editDisabled,
  isCreate,
}) => {
  const valueLabelFormat = (value: number) => {
    return value + "%";
  };

  useTitle(newRule?.name);

  const changeDate = (date_type: string, date: Moment | null) => {
    onChangeHandler({
      target: { name: date_type, type: "moment", value: date },
    });
  };

  const clearDate = (date_type: string) => {
    onChangeHandler({
      target: { name: date_type, value: null },
    });
  };

  const { config } = useConfig();
  if (Object.keys(newRule).length === 0) return <Loader />;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={2}>
        <Paper sx={{ p: 1 }}>
          <Accordion
            style={{ margin: "0px 0px 10px 0px", boxShadow: "none" }}
            defaultExpanded={isCreate}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h6">{newRule.name}</Typography>
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    fontSize="small"
                  >
                    Type:{" "}
                  </Typography>
                  <Typography variant="body2" display="inline" fontSize="small">
                    {newRule.type === "mab"
                      ? "Multi-armed bandit"
                      : newRule.type === "rollout"
                      ? "Rollout"
                      : "A/B-Test"}
                  </Typography>
                </Grid>
                <Grid item xs={4} container>
                  <Grid item container xs={12}>
                    <Typography variant="caption">
                      Traffic allocation
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems={"center"}
                    columnSpacing={2}
                  >
                    <Grid item xs={6}>
                      <Box sx={{ width: "100%", mr: 12 }}>
                        <LinearProgress
                          variant="determinate"
                          value={sliderAllocation}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {sliderAllocation}%
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    value={newRule.name || ""}
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    onChange={onChangeHandler}
                    disabled={editDisabled}
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  md={6}
                  alignContent="flex-end"
                >
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {config.cms_strings.experiments.description_tooltip}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      value={newRule.description || ""}
                      name="description"
                      label="Description"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      onChange={onChangeHandler}
                      disabled={editDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Alert severity="info" sx={{ minHeight: "68px" }}>
                      The hypothesis should be written as per the format below.
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      value={newRule.hypothesis || ""}
                      name="hypothesis"
                      label="Hypothesis"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      onChange={onChangeHandler}
                      disabled={editDisabled}
                    />
                  </Grid>
                </Grid>
                {!config.user.region &&
                newRule.meta &&
                newRule.meta.version >= 1 ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      value={newRule.owner || ""}
                      name="owner"
                      label="Owner"
                      type="text"
                      fullWidth
                      onChange={onChangeHandler}
                      disabled={editDisabled}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {!config.user.region &&
                newRule.type !== "rollout" &&
                newRule.meta &&
                newRule.meta.version >= 1 ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <TextField
                        margin="dense"
                        value={newRule.developer || ""}
                        name="developer"
                        label="Developer"
                        type="text"
                        fullWidth
                        onChange={onChangeHandler}
                        disabled={editDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Planned start date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          timezone="UTC"
                          format="YYYY-MM-DD"
                          value={
                            newRule.planned_start_date
                              ? moment.utc(newRule.planned_start_date)
                              : null
                          }
                          onChange={(value) =>
                            changeDate("planned_start_date", value)
                          }
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: () => clearDate("planned_start_date"),
                            },
                          }}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Planned end date
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker
                          timezone="UTC"
                          format="YYYY-MM-DD"
                          value={
                            newRule.planned_end_date
                              ? moment.utc(newRule.planned_end_date)
                              : null
                          }
                          onChange={(value) =>
                            changeDate("planned_end_date", value)
                          }
                          slotProps={{
                            field: {
                              clearable: true,
                              onClear: () => clearDate("planned_end_date"),
                            },
                          }}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid item xs={12} md={6} container>
                  <Grid item xs="auto">
                    <Typography variant="caption">
                      Traffic allocation
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <HelpToolTip
                      text={
                        config.cms_strings.experiments
                          .traffic_allocation_tooltip
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Slider
                      value={sliderAllocation}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      valueLabelFormat={valueLabelFormat}
                      onChange={handleSliderChange}
                      onChangeCommitted={handleSliderCommitted}
                      disabled={isCreate}
                    />
                    <Box style={{ marginTop: "-10px" }}>
                      <span style={{ float: "left" }}>0%</span>
                      <span style={{ float: "right" }}>100%</span>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} container>
                  {newRule.meta && newRule.meta.version >= 1 ? (
                    <ChangeDecisionPages
                      currentPages={newRule.meta.decision_pages}
                      handlePageChange={onChangeHandler}
                      editDisabled={editDisabled}
                    />
                  ) : (
                    <ChangeDecisionPage
                      currentPage={newRule.decision_page}
                      handlePageChange={onChangeHandler}
                      editDisabled={editDisabled}
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Stack>
    </LocalizationProvider>
  );
};

export default GeneralFields;
