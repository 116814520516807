export const ChangeHandler = (event: any, setData: any) => {
  const { name, value, type } = event.target;
  let newValue: any;
  switch (type) {
    case "moment":
      newValue =
        value && value.isValid()
          ? name === "planned_start_date"
            ? value.startOf("day").format("YYYY-MM-DD HH:mm:ss")
            : value.endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : null;
      break;
    case "number":
      newValue = Number(value);
      break;
    default:
      newValue = value;
      break;
  }

  setData((prevState: any) => {
    if (
      prevState.meta &&
      prevState.meta.version >= 1 &&
      name === "decision_page"
    ) {
      return {
        ...prevState,
        meta: {
          ...prevState.meta,
          decision_pages: value,
        },
      };
    } else {
      return {
        ...prevState,
        [name]: newValue,
      };
    }
  });
};
