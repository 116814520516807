export const cms_strings = {
  audiences: {
    save_to_global_tooltip:
      "By checking this, the audience will be saved to the global audiences and may then be used by anyone.",
  },
  results: {
    value_tooltip: "Value tooltip",
    conversion_tooltip: "Conversions tooltip",
    conversio_rate_tooltip:
      "The percentage of unique conversions for visitors who were exposed to this variation and triggered this event at least once.",
    improvement_tooltip:
      "Improvement is the difference between the variation and the control group.",
    converting_visitors_tooltip:
      "The number of visitors that have executed the event at least once.",
    confidence_tooltip: "Confidence interval tooltip.",
    statsig_tooltip: "Statsig tooltip.",
    experiment_config_tooltip: "Experiment configuration.",
    datepicker_tooltip:
      "Selecting a start and stop date will show all experiments that have been running at some point between those dates.",
  },
  features: {
    feature_archived: "This feature has been archived!",
    feature_no_exists: "This feature doesn't exist!",
  },
  experiments: {
    decision_page_tooltip:
      "Set this to the page the experiment is running. If your experiment is running on several different type of pages, pick Other.",
    description_tooltip:
      "The description should contain what changes are being introduced and where.",
    hypothesis_tooltip:
      "A hypothesis should contain IF - THEN -  BECAUSE statements.",
    hypothesis_tooltip_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/pages/808628414/2+Requirements+refining+the+hypothesis",
    traffic_allocation_tooltip:
      "Set the share of traffic (passing the audience check) to be included in the experiment. The traffic allocation can not be decreased when the experimetn has been started.",
    guardrail_metrics_tooltip:
      "Guardrail metrics are added automatically to your experiment.",
    guardrail_metrics_tooltip_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/overview?homepageId=161685606",
    add_variations_disabled_tooltip:
      "You can't add more variations after it has been live",
    state_change_30:
      "Are you sure you want to set the state to Live? This will publish the experiment to production and it will be visible to all visitors qualifying for the configured audiences.",
    state_change_30_clashes:
      "This experiment is clashing with another experiment. Are you sure you want to set the state to Live? The owner(s) of the clashing experiment(s) will be notified.",
    state_change_40:
      "Are you sure you want to stop the experiment? You will not be able to start it again.",
    duplicate_metrics_error: "You have duplicate metrics!",
    distribution_sum_error:
      "The sum of the distribution in all variations must be 100%!",
    decrease_allocation_after_live_error:
      "You can't decrease allocation after it has been live!",
    feature_archived:
      "This experiment belongs to a feature that has been archived. No changes can be made.",
    duplicate_audience_error: "An audience with identical data already exists",
    aucience_saved: "Audience saved",
    unsaved_changes_title: "Unsaved changes",
    unsaved_changes_desc:
      "You have unsaved changes! Are you sure you want to leave this page?",
    edit_not_allowed: "You are not allowed to edit this experiment",
    mandatory_fields_on_create:
      "Experiment name and experiment page are mandatory fields",
    type_tooltip: "The type of experiment",
    type_tooltip_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/overview?homepageId=161685606",
    clashes_warning:
      "Your experiment clashes with other experiments. Either change your locales of your experiment, change the timings or collaborate with the owner of the clashing experiment(s)",
  },
  metrics: {
    metric_name_tooltip:
      "Use a name that makes it easy to understand the metric on the results page.",
    winning_direction_tooltip: "The desired impact - what defines a success.",
    department_filter_tooltip:
      "This optional setting let you target events on a specific department only",
    department_filter_disabled_tooltip:
      "The department filter is disabled for this type of metric",
    custom_filter_info:
      "Custom filters is an advanced feature of Fabulous that let you filter events based on custom properties. ",
    custom_filter_info_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/pages/1402339384/Events+filtering",
    metricset_tooltip:
      "A metricset is your teams stored set of metrics. You can use the same metricset for several experiments. Click on the arrow-icon to use the stored metricset in this epxeriment (note - no metrics must be added to the experiment for this to work). To store a metricset, click on the save icon (note - the stored metricset will be overwritten).",
    metricset_tooltip_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/",
    metricset_confirm:
      "Are you sure you want to update the metricset for your team? This will overwrite the stored metricset!",
    metricset_notfound: "No stored metricset found!",
    event_name_tooltip:
      "The event tracked, this can be found in the payload sent to DEP under the property eventName",
    test_direction_tooltip:
      "The test direction, i.e do you want to detect an increase or a decrease - or both (both takes longer).",
    test_type_tooltip: "The type of metric, i.e. what you are testing.",
    currency_tooltip: "Is this a monetary metric?",
    pointer_tooltip:
      "Should we look for a specific property in the payload? (optional)",
    custom_metric_info: "Custom metrics is an advanced feature of Fabulous.",
    custom_metric_info_link:
      "https://hmgroup.atlassian.net/wiki/spaces/BTCAEWVA/",
  },
};
